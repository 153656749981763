/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { FaAppStoreIos } from 'react-icons/fa6';
import { TfiAndroid } from 'react-icons/tfi';

const Hero = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const isAuthenticated = useSelector(state => state.main.isAuthenticated);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    return (
        <Box style={{ background: 'white' }} alignContent={'center'}>
            <Container>
                <Grid sx={{ marginTop: { xs: '0px', md: '0px' } }} minHeight="400px">
                    <Grid paddingTop={10} paddingBottom={10}>
                        <Box width={'100%'}
                            data-aos={isMd ? 'fade-right' : 'fade-up'}
                            display="flex"
                            flexDirection={'column'}
                            justifyContent={'center'}
                            position={'relative'}
                            sx={{

                                height: { xs: '250px', md: '300px' },
                                borderRadius: '20px',
                                //*backgroundImage: 'linear-gradient(106deg, #FFFCD6 1.67%, #E4FDFF 1.68%, #F9FFF1 97.46%)',
                                '&:after': {
                                    content: '""',
                                    //  backgroundImage: 'linear-gradient(105deg, #FFEAEC 0%, #D8F1FF 100%);',
                                    left: 0, right: 0, top: 0, bottom: 0,
                                    position: 'absolute',
                                    opacity: 0,
                                    borderRadius: '20px',
                                    transition: 'all 1s ease-in-out'
                                },
                                '&:hover:after': { opacity: 1 }
                            }}>
                            <Box
                                sx={{

                                    paddingX: { xs: '10px', md: '48px' }
                                }}
                                position={'relative'} zIndex={2}>
                                <Box marginBottom={2} textAlign={'center'}>
                                    <Typography fontSize={14}>{t('areYouReady')} </Typography>
                                    <Box textAlign="center" marginBottom={'30px'} marginTop={'30px'}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() => history.push(isAuthenticated ? '/profile/meetings' : '/login?redirectUrl=profile/meetings')}
                                            sx={{ fontWeight: 500, width: 240 }}


                                        >

                                            <Typography fontSize={12}>{t('createQuiclyMeetingNowButton')} </Typography>
                                        </Button>

                                    </Box>
                                    <center>
                                        <Box
                                            textAlign="center"
                                            marginBottom={'30px'}
                                            marginTop={'30px'}
                                            display="flex"
                                            justifyContent="space-between"
                                            width="100%"
                                            maxWidth={220}
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center"
                                                marginBottom={2}>
                                                <FaAppStoreIos size={24} color="#b8c0c0" title='App Store' />
                                                <Typography fontSize={11} color="#2f3030">Appstore</Typography>
                                            </Box>

                                            <Box display="flex" flexDirection="column" alignItems="center"
                                                marginBottom={2}>
                                                <IoLogoGooglePlaystore size={24} color="#b8c0c0" title='Play Store' />
                                                <Typography fontSize={11} color="#2f3030">Play Store</Typography>
                                            </Box>

                                            <Box display="flex" flexDirection="column" alignItems="center"
                                                marginBottom={2}>
                                                <TfiAndroid size={24} color="#b8c0c0" title='Android APK' />
                                                <Typography fontSize={11} color="#2f3030">Android</Typography>
                                            </Box>
                                        </Box>
                                    </center>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </Box>);
};

export default Hero;
