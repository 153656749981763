import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {MeetingServices, UserServices} from '../../../../../../services';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import * as yup from 'yup';
import moment from 'moment';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import TimeTextField from '../../../../../../common/TimeTextField';

import Typography from '@mui/material/Typography';

const filter = createFilterOptions();

yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = moment(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date('');
    });
});

yup.addMethod(yup.string, 'mName', function (errorMessage) {
    return this.test('test-mName', errorMessage, function (value) {
        const {path, createError} = this;

        if (value !== undefined) {
            if (value.indexOf('/*/*') > -1) return false;
        }

        return true || createError({path, message: errorMessage});
    });
});

const PlanMeetingForm = ({setVisibleModal, getMeetings}) => {
    const {t} = useTranslation();
    let strTime = moment()
        .add(1, 'hours')
        .set('minutes', 0);
    let endTime = moment()
        .add(2, 'hours')
        .set('minutes', 0);
    const [companyUsers, setCompanyUsers] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [endDate, setEndDate] = useState(null);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const initialValues = {
        name: '',
        userIds: [],
        selectedDays: [],
        startingDate: strTime,
        endDate: endTime,
        description: '',
    };

    const validationSchema = yup.object({
        name: yup
            .string(t('requiredMeetingTitle'))
            .min(2)
            .trim()
            .required(t('requiredMeetingTitle'))
            .mName(t('validMeetingTitle')),
        userIds: yup
            .mixed()
            .test('test-cert1', t('requiredAddParticipant'), (value) => {
                return Array.isArray(value) && value.length > 0;
            }),
        startingDate: yup
            .date(t('validDate'))
            .min(new Date(), t('validDate'))
            .nullable(false),
        endDate: yup
            .date(t('validDate'))
            .min(moment(endDate ? endDate : new Date()), t('validDate'))
            .nullable(false),
    });

    const onSubmit = (values) => {
        setButtonIsLoading(true);
        values.startingDate = moment(values.startingDate)
            .local()
            .format();
        values.endDate = moment(values.endDate)
            .local()
            .format();
        values.freeJoin = true;

        console.log(values);

        var promise = MeetingServices.createMeeting(values);

        promise.then((response) => {
            setButtonIsLoading(false);
            setVisibleModal(false);
            getMeetings();
            if (response && response.data) {
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    timer: 4000,
                    timerProgressBar: true,
                    showCloseButton: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('ok'),
                    text: t('successPlanMeeting'),
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then(() => null);
            }
        });
    };
    const formik = useFormik({
        initialValues,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit,
    });

    useEffect(() => {
        let promise = UserServices.getAllCompanyUsers();
        promise.then((response) => {
            if (response && response.data && response.data.length > 0) {
                setCompanyUsers(response.data);
            }
        });
    }, []);

    const ValidateEmail = (email) => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validRegex)) {
            alert(t('validEmail'));
            return false;
        }
        return true;
    };

    return (
        <Box sx={{marginBottom: '40px'}}>
     
            <form onSubmit={formik.handleSubmit}>
                <Grid spacing={1} container width={'100%'} paddingTop={2}>
                    <Grid item xs={12} paddingBottom={2}>
                        <Box>
                            <Box sx={{
                                fontWeight: 600,
                                marginBottom: '10px',
                                fontSize: '17px',
                                paddingLeft: '4px',
                                color: '#424242'
                            }}>
                                {t('meetingTitleRequired')}
                            </Box>
                            <TextField
                                placeholder={'Ör. Tanışma Toplantısı'}
                                variant="outlined"
                                name={'name'}
                                type={'text'}
                                className={'border-gray'}
                                fullWidth
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                FormHelperTextProps={{
                                    style: {
                                        backgroundColor: '#EFEFEF',
                                        padding: 0,
                                        margin: 0,
                                    },
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} display={'flex'}>
                        <Box sx={{
                            fontWeight: 600,
                            marginBottom: '0px',
                            fontSize: '17px',
                            paddingLeft: '4px',
                            color: '#424242'
                        }}>
                            {t('date')}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} paddingBottom={2} display={'flex'}>
                        <DesktopDatePicker
                            disablePast
                            name={'startingDate'}
                            inputFormat="dd/MM/yyyy"
                            value={formik.values.startingDate}
                            onChange={(value) => {
                                formik.setFieldValue('startingDate', moment(value));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={'border-gray'}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                            )}
                        />

                        <TimeTextField
                            id="time"
                            type="time"
                            name={'startingDate'}
                            mindate={new Date()}
                            value={moment(formik.values.startingDate).format('HH:mm')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 1800,
                            }}
                            className={'border-gray'}
                            onChange={(value) => {
                                let startingDate = formik.values.startingDate;
                                let hour = value.split(':')[0];
                                let minutes = value.split(':')[1];
                                let newDate = moment(startingDate)
                                    .set('hours', hour)
                                    .set('minutes', minutes);
                                if (moment() < newDate) {
                                    formik.setFieldValue('startingDate', newDate);
                                }
                            }}
                            sx={{width: 285, marginLeft: 1, marginRight: '5px'}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} paddingBottom={2} display={'flex'}>
                        <DesktopDatePicker
                            minDate={new Date(formik.values.startingDate)}
                            disablePast
                            name={'endDate'}
                            inputFormat="dd/MM/yyyy"
                            value={formik.values.endDate}
                            onChange={(value) => {
                                let endDate = formik.values.endDate;
                                let startingDate = formik.values.startingDate;

                                if (endDate > startingDate) {
                                    let newDate = moment(value)
                                        .set('hours', moment(startingDate).hour())
                                        .set('minutes', moment(startingDate).minutes() + 30);
                                    formik.setFieldValue('endDate', newDate);
                                } else {
                                    formik.setFieldValue('endDate', moment(value));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    onKeyDown={(e) => e.preventDefault()}
                                    {...params}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    style={{marginLeft: '5px'}}
                                    className={'border-gray'}
                                />
                            )}

                        />

                        <TimeTextField
                            id="time"
                            type="time"
                            name={'endDate'}
                            className={'border-gray'}
                            mindate={moment(formik.values.endDate)}
                            value={moment(formik.values.endDate).format('HH:mm')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            onChange={(value) => {
                                let endDate = formik.values.endDate;
                                let startingDate = formik.values.startingDate;
                                let hour = value.split(':')[0];
                                let minutes = value.split(':')[1];
                                let newDate = moment(endDate)
                                    .set('hours', hour)
                                    .set('minutes', minutes);
                                if (newDate > startingDate) {
                                    formik.setFieldValue('endDate', newDate);
                                }
                            }}
                            sx={{width: 285, marginLeft: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} paddingBottom={2}>
                        <Box>
                            <Box sx={{
                                fontWeight: 600,
                                marginBottom: '10px',
                                fontSize: '17px',
                                paddingLeft: '4px',
                                color: '#424242'
                            }}>
                                {t('addParticipants')}
                            </Box>
                            <Autocomplete
                                freeSolo
                                multiple
                                fullWidth
                                clearOnBlur
                                handleHomeEndKeys
                                options={companyUsers}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        className={'border-gray'}
                                        name={'userIds'}
                                        placeholder={t('addParticipants')}
                                        variant="outlined"
                                        error={
                                            formik.touched.userIds && Boolean(formik.errors.userIds)
                                        }
                                        helperText={formik.touched.userIds && formik.errors.userIds}
                                    />
                                )}
                                value={formik.values.userIds}
                                placeholder={t('addParticipants')}
                                onKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        return null;
                                    }
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.fullName;
                                }}
                                onChange={(e, values) => {
                                    if (values.length > 0) {
                                        let item = values[values.length - 1];
                                        if (typeof item === 'object') {
                                            if (values.length > 0) {
                                                let isNewItem = item.email
                                                    ? item.email.includes(t('add') + ' ')
                                                    : false;
                                                if (isNewItem) {
                                                    item.email = isNewItem
                                                        ? item.email.split(t('add') + ' ')[1]
                                                        : item.email;
                                                    item.fullName = isNewItem
                                                        ? item.fullName.split(t('add') + ' ')[1]
                                                        : item.fullName;
                                                }
                                                if (ValidateEmail(item.email ? item.email : item)) {
                                                    isNewItem ? companyUsers.push(item) : null;
                                                    formik.setFieldValue('userIds', values);
                                                }
                                            } else {
                                                formik.setFieldValue('userIds', values);
                                            }
                                        } else {
                                            if (ValidateEmail(item)) {
                                                let newUser = {
                                                    email: item,
                                                    fullName: item,
                                                    id: formik.values.userIds.length + 1,
                                                };
                                                let currentUsers = [...formik.values.userIds];
                                                currentUsers.push(newUser);
                                                companyUsers.push(newUser);
                                                formik.setFieldValue('userIds', currentUsers);
                                            }
                                        }
                                    } else {
                                        formik.setFieldValue('userIds', values);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const {inputValue} = params;
                                    const isExisting = options.some(
                                        (option) => inputValue === option.email,
                                    );

                                    if (inputValue !== '' && !isExisting) {
                                        let newEmail = {
                                            email: t('add') + ' ' + inputValue,
                                            fullName: t('add') + ' ' + inputValue,
                                            id: filtered.length + 1,
                                        };
                                        filtered.push(newEmail);
                                    }
                                    return filtered;
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item container xs={12}>
                        <Box display="flex" width={'100%'}>
                            <LoadingButton
                                loading={buttonIsLoading}
                                size={'large'}
                                variant={'contained'}
                                type={'submit'}
                                disabled={buttonIsLoading || !(formik.isValid && formik.dirty)}
                                sx={{
                                    width: '100%',
                                    padding: '15px',
                                    background: 'linear-gradient(to right, #d32f2f, #f44336, #ff5252) !important',
                                    color: '#fff !important'
                                }}
                            >
                                {t('plan')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

PlanMeetingForm.propTypes = {
    setVisibleModal: PropTypes.func,
    getMeetings: PropTypes.func,
};

export default PlanMeetingForm;
