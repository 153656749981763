import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import MeetingFormForTab from './Meetings/components/MeetingFormForTab';
import PlanMeetingFormForTab from './Meetings/components/PlanMeetingFormForTab';

const style = {
  width: '100%',
  bgcolor: 'background.paper',
  p: 3,
  '&::-webkit-scrollbar': {
    width: '0px',
  },
};

function ModalWithTabs() {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: '99%', margin: '0 auto' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#f44336', //altçizgi
            height: 4,
            borderRadius: '4px 4px 0 0',
          },
          '& .MuiTab-root': {
            textTransform: 'none',
            minWidth: 120,
            fontWeight: 600,
            fontSize: '1rem',
            marginRight: '20px',
            '&:hover': {
              color: '#f44336', //mouse üzerinde
              opacity: 1,
            },
            '&.Mui-selected': {
              color: '#f44336', // seçili
              fontWeight: 700,
            },
          },
        }}
      >
        <Tab label={t('newMeeting')} />
        <Tab label={t('planMeeting')} />
      </Tabs>

      <Box sx={{ p: 3 }}>
        {value === 0 && (
          <MeetingFormForTab getMeetings={() => { /* Get meetings function */ }} />
        )}
        {value === 1 && (
          <Box sx={{ maxWidth: '80%', margin: '0 auto' }}>
            <PlanMeetingFormForTab
              setVisibleModal={() => { /* Toggle modal visibility */ }} 
              getMeetings={() => { /* Get meetings function */ }} 
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ModalWithTabs;
