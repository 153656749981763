import React, {Suspense, useEffect, useState} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Routes from './routers/Routes';
import {CookiesProvider} from 'react-cookie';
import {Provider} from 'react-redux';
import store from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import 'react-image-lightbox/style.css';
import 'assets/scss/style.scss';
import {setMobile, setPageSize} from './redux/actions';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import trLocale from 'date-fns/locale/tr';
import enLocale from 'date-fns/locale/en-US';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {useTranslation} from 'react-i18next';
import arLocale from 'date-fns/locale/ar';
import ruLocale from 'date-fns/locale/ru';
import {ParallaxProvider} from 'react-scroll-parallax';

const browserHistory = createBrowserHistory();
let persistor = persistStore(store);

const App = () => {
    const {i18n} = useTranslation();
    const [locale, setLocale] = useState(trLocale);

    const setDimension = () => {
        const mobile = window.innerWidth <= 1024;
        store.dispatch(setMobile(mobile));
        store.dispatch(setPageSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        }));
    };

    useEffect(() => {
        setDimension();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        });
    }, [window.innerWidth, window.innerHeight]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        switch (i18n.language) {
            case 'tr':
                setLocale(trLocale);
                break;
            case 'en':
                setLocale(enLocale);
                break;
            case 'ar':
                setLocale(arLocale);
                break;
            case 'ru':
                setLocale(ruLocale);
                break;
            default:
                setLocale(trLocale);
        }
    }, [i18n.language]);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={browserHistory}>
                    <CookiesProvider>
                        <ParallaxProvider>
                            <Suspense fallback={CircularProgress}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                                    <Routes/>
                                </LocalizationProvider>
                            </Suspense>
                        </ParallaxProvider>
                    </CookiesProvider>
                </Router>
            </PersistGate>
        </Provider>
    );
};

export default App;
