export const light = {
  alternate: {
    main: '#424242',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#f44336',
    light: '#f44336',
    dark: '#f44336',
    contrastText: '#fff',
  },
  secondary: {
    light: '#EFEFEF',
    main: '#EFEFEF',
    dark: '#ECEBEE',
    contrastText: '#000',
  },
  google: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#FFFFFF',
    contrastText: '#4A4B4B',
  },
  text: {
    primary: '#f44336',
    secondary: '#060000',
    tertiary: '#4A4B4B'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};

export const dark = {
  alternate: {
    main: '#5E35D1',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#5E35D1',  
    light: '#E65A60', 
    dark: '#8A151A', 
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#2d3748',
    secondary: '#646e73',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};
