import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import {IconButton} from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import visaIcon from './../../../../svg/logos/visa.png';
import mastercartIcon from './../../../../svg/logos/mastercart.png';
import iyzicoIcon from './../../../../svg/logos/iyzico.svg';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from 'react-i18next';

const Footer = () => {

  const {t} = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Grid container spacing={2} justifyContent={{sm: 'center', md: 'space-between'}}>
      
      {/*
      <Grid item xs={12} md={2}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{xs: 'center', md: 'start'}}
          width={'100%'}
          flexDirection={'column'}
        >
          <Box display={'flex'} sx={{'& .MuiIconButton-root:hover': {color: 'white'}}}>
            <Box>
              <IconButton
                onClick={() => {
                  openInNewTab('https://www.facebook.com/people/Telepati-Meeting/100085372215614/');
                }}
                aria-label="Facebook"
                sx={{color: '#AED0EC', '&:hover': {color:'#f44336 !important'}}}
              >
                <FacebookOutlinedIcon fontSize="medium"/>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  openInNewTab('https://twitter.com/TelepatiMeeting');
                }}
                aria-label="Twitter"
                sx={{color: '#AED0EC', '&:hover': {color:'#f44336 !important'}}}
              >
                <TwitterIcon fontSize="medium"/>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  openInNewTab('https://www.instagram.com/telepatimeeting/');
                }}
                aria-label="Instagram"
                sx={{color: '#AED0EC', '&:hover': {color:'#f44336 !important'}}}
              >
                <InstagramIcon fontSize="medium"/>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  openInNewTab('https://linkedin.com/company/telepatimeeting');
                }}
                aria-label="LinkedIn"
                sx={{color: '#AED0EC', '&:hover': {color:'#f44336 !important'}}}
              >
                <LinkedInIcon fontSize="medium"/>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Grid> */}
      <Grid
        item
        xs={12}
        md={10}

      >
        <Grid
          item
          container
          width={'100%'}
          sx={{
            paddingTop:'7px',
            '& a': {color: '#b8c0c0', textDecoration: 'none', fontWeight:500,fontSize: {xs: '11px', md: '14px'}},
            '& a:hover': {textDecoration: 'underline !important'},
            textAlign: {md: 'right', xs: 'center'},
            justifyContent: {md: 'right', xs: 'center'},
          }}
        >

          <Grid item xs={12} md={9} sx={{textAlign: {xs: 'center', md: 'center'}}}>
            <Link to="/privacy-policy" sx={{marginLeft: {xs: 0, md: '10%'}}}>
              {t('privacy')}
            </Link>
            <Link to="/sales-policy" style={{marginLeft: '10%'}}
            >
              {t('salesContract')}
            </Link>
            <Link to="/contact-us" style={{marginLeft: '10%'}}>
              {t('contactUs')}
            </Link>
            <Link to="" style={{marginLeft: '10%'}}>
              © 2024 Telepati
              </Link>
          </Grid>
       {/* Satış iconları
          <Grid
            item xs={12} md={3} style={{alignItems:'center', display:'flex', justifyContent:'flex-end',direction: 'ltr'}}
            sx={{mt: {xs: 2, md: 0}}}
          >
            <img src={iyzicoIcon} width={54} style={{marginTop: '7px'}}/>
            <img src={visaIcon} width={49} style={{marginLeft: 15}}/>
            <img src={mastercartIcon} width={33} style={{marginLeft: 15}}/>
          </Grid>
*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
