import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import PlanMeetingForm from './components/PlanMeetingForm';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const PlanMeetingModal = ({visible, setVisible, userCurrentProduct, getMeetings}) => {
    let disablePlanMeeting = () => {

        if (userCurrentProduct.title !== 'pricingDefault') {
            return !userCurrentProduct.createMeetingForLater;
        } else {
            return true;
        }
    };

    return (
        <Modal
            onClose={() => setVisible(false)}
            open={visible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '700px',
                width: '100%',
                minHeight: '590px',
                backgroundColor: '#fff',
                border: '0px solid #000',
                borderRadius: '15px',
                boxShadow: 24,
                padding: '60px 20px 20px 20px',
                paddingBottom: 5,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '0px'
                }
            }}>

                <a style={{
                    backgroundColor: '#F0F5FF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100%',
                    width: '34px',
                    height: '34px',
                    position: 'absolute', right: '20px', top: '20px'
                }} onClick={() => {
                    setVisible(false);
                }}>
                    <CloseRoundedIcon/>
                </a>
                <Box style={{maxWidth:'630px', margin: '0 auto'}}>
                    <PlanMeetingForm
                        getMeetings={getMeetings}
                        setVisibleModal={setVisible}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

PlanMeetingModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    getMeetings: PropTypes.func,
    userCurrentProduct: PropTypes.object
};

export default PlanMeetingModal;
