import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Home} from 'views/landingPages';
import DisplayNameModal from './components/DisplayNameModal';
import {useTranslation} from 'react-i18next';
import {Base64} from 'js-base64';
import {v4 as uuidv4} from 'uuid';

const MeetingGo = ({match: {params: {meetingId, lang}}}) => {
    const {i18n} = useTranslation();
    const urlParams = new URLSearchParams(Base64.decode(meetingId));
    const paramsUserID = urlParams.get('userId');
    const paramsMeetingID = urlParams.get('meetingId');
    const paramsUserName = urlParams.get('userName');
    const paramsEmail = urlParams.get('email');

    const LoginUserName = useSelector((state) => state.main.user.name + ' ' + state.main.user.surname);
    const LoginUserEmail = useSelector((state) => state.main.user.email);
    const LoginUserID = useSelector((state) => state.main.user.id);

    const [displayMeetingID, setDisplayMeetingID] = useState(paramsMeetingID);
    const [displayUserID, setDisplayUserID] = useState(LoginUserID);
    const [displayName, setDisplayName] = useState(LoginUserName);
    const [displayEmail, setDisplayEmail] = useState(LoginUserEmail);

    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (!LoginUserName && paramsUserName)
            setDisplayName(paramsUserName);

        if (!LoginUserEmail && paramsEmail)
            setDisplayEmail(paramsEmail);

        if (!LoginUserID && paramsUserID)
            setDisplayUserID(paramsUserID);
        else
            setDisplayUserID(uuidv4());

        setDisplayMeetingID(paramsMeetingID);
    }, [paramsUserID, paramsEmail, paramsUserName, paramsMeetingID]);

    //Dil değişimi için gerekli
    useEffect(() => {
        if (lang == 'en' || lang == 'tr' || lang == 'fr') i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        if (displayName && displayEmail && displayUserID && displayMeetingID) {
            setModalVisible(false);
            handleRedirect();
        } else {
            setModalVisible(true);
        }
    }, [displayName, displayEmail, displayUserID, displayMeetingID]);

    const handleRedirect = () => {

        let meetingUrl = `?meetingId=${displayMeetingID}`;
        meetingUrl += `&email=${displayEmail}`;
        meetingUrl += `&userId=${displayUserID}`;
        meetingUrl += `&userName=${displayName}`;
        let newUrl = Base64.encode(meetingUrl);

        window.location.assign(`${process.env.REACT_APP_PROJECT_COMPANY}${lang}/meeting/${newUrl}`);
    };

    return (
        <>
            <Home/>
            <DisplayNameModal
                visible={modalVisible}
                setVisible={setModalVisible}
                setDisplayName={setDisplayName}
                displayEmail={displayEmail}
                setDisplayEmail={setDisplayEmail}
            />
        </>
    );
};

MeetingGo.propTypes = {
    match: PropTypes.object
};

export default MeetingGo;
