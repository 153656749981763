import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import { Form } from './components';

import Grid from '@mui/material/Grid';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SignupSimple = (props) => {
  const { match: { params: { lang } } } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    // console.log('params', props);
    // console.log('lang', localStorage);
    if (lang === 'en' || lang === 'tr') {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <Grid
      container
      minHeight={'calc(100vh)'}
      sx={{ backgroundColor: '#F0F5FF' }}
    >

      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        xs={0}
        md={0}
        backgroundColor="#F6F6F6">
        <Box display={'flex'} flexGrow={'1'} alignItems={'center'} height={'100%'}>
          <Container maxWidth={'440px'}>

            <Form />
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

SignupSimple.propTypes = {
  match: PropTypes.object
};

export default SignupSimple;
